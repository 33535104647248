// TypeScript
import axios from 'axios';
import { type CatalogParams, type Client, type CreateCatalogApplicationDto, type OrganizationApplication, type UnifiedApplication } from '../../interfaces/Client/Client';
import { getOrgAppByUrl, listOrganizationApplication } from '../OrganizationsAPI/organizationApplicationService';
import { getTotalOrgClientsByOrgId } from '../AdminAPI/adminService';
import { extractClientAppName } from '../../utils';
import { findUrlForClient } from '../../utils/client-app-libs/findUrlForClient';
import { captureException, captureMessage } from '@sentry/nextjs';

export async function createCatalogApplication(catalogData: CreateCatalogApplicationDto) {
  try {
    const response = await axios.post('/api/catalog/create', catalogData);

    return response.data;
  } catch (error) {
    console.error('Error creating catalog application:', error);
    throw error;
  }
}

export async function getCatalogApplication(uuid: string) {
  try {
    const response = await axios.get(`/api/catalog/${uuid}`);

    return response.data;
  } catch (error) {
    console.error('Error fetching catalog application:', error);
    throw error;
  }
}

export async function updateCatalogApplication(params: CatalogParams) {
  try {
    const response = await axios.post(`/api/catalog/${params.uuid}`, params.catalogData);

    return response.data;
  } catch (error) {
    console.error('Error updating catalog application:', error);
    throw error;
  }
}

export async function deleteCatalogApplication(uuid: string) {
  try {
    const response = await axios.delete(`/api/catalog/${uuid}`);

    return response.data;
  } catch (error) {
    console.error('Error deleting catalog application:', error);
    throw error;
  }
}

export async function listCatalogApplications() {
  try {
    const response = await axios.get('/api/catalog/list', { params: { enabled: true } });

    return response.data;
  } catch (error) {
    console.error('Error listing catalog applications:', error);
    throw error;
  }
}

// appCatalog Application Helpers

export const fetchCatalogApplications = async (): Promise<UnifiedApplication[]> => {
  try {
    return await listCatalogApplications();
  } catch (error) {
    throw new Error('Failed to fetch catalog applications');
  }
};

export const fetchOrganizationApplications = async (organizationId: string): Promise<OrganizationApplication[]> => {
  try {
    return await listOrganizationApplication(organizationId);
  } catch (error) {
    throw new Error('Failed to fetch organization applications');
  }
};

export const fetchSSOEnabledClients = async (organizationId: string): Promise<Client[]> => {
  try {
    const ssoEnabledClients = await getTotalOrgClientsByOrgId(organizationId);

    return Array.isArray(ssoEnabledClients) ? ssoEnabledClients : [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (error.status === 500) {
      return [];
    }

    throw new Error('Failed to fetch SSO enabled clients');
  }
};

export const transformSSOEnabledClients = (clients: Client[]): UnifiedApplication[] => {
  return clients
    .filter(client => client.sso)
    .map(client => ({
      uuid: client.client_id,
      name: extractClientAppName(client.name),
      logo: client.logo_uri,
      customUrl: client.addons.samlp.recipient,
      enabled: true,
      type: 'ssoEnabled',
      signing_keys: client.signing_keys,
      callbacks: client.callbacks,
      categories: [], // add missing property
    }));
};

export const mergeApplications = (
  catalogApps: UnifiedApplication[],
  orgApps: OrganizationApplication[],
  ssoEnabledApps: UnifiedApplication[],
): { catalogApplications: UnifiedApplication[]; selectedApplications: UnifiedApplication[] } => {
  const selectedApplications: UnifiedApplication[] = [...ssoEnabledApps];
  const catalogApplications: UnifiedApplication[] = [];

  catalogApps.forEach(catalogApp => {
    const matchingOrgApps = orgApps.filter(orgApp => orgApp.applicationId === catalogApp.uuid);

    if (matchingOrgApps.some(app => app.enabled)) {
      const enabledApp = matchingOrgApps.find(app => app.enabled);

      selectedApplications.push({
        ...enabledApp,
        name: catalogApp.name,
        logo: catalogApp.logo,
        customUrl: enabledApp?.customUrl || catalogApp.baseUrl,
        type: 'organization',
        sso: catalogApp.sso,
        ssoUrlExample: catalogApp.ssoUrlExample,
        categories: [],
        callbacks: [],
        uuid: '',
      });
    } else {
      catalogApplications.push({
        ...catalogApp,
        enabled: false,
        type: 'catalog',
        logo: catalogApp.logo,
        baseUrl: catalogApp.baseUrl || findUrlForClient((catalogApp.name as string) || ''),
        categories: catalogApp.categories,
      });
    }
  });

  return { catalogApplications, selectedApplications };
};

// utils/customApplicationHelpers.ts

export const fetchUrlClients = async (organizationId: string): Promise<UnifiedApplication[]> => {
  try {
    const orgAppsByUrl = await getOrgAppByUrl(organizationId);
    const urlClientNames = new Set<string>();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return orgAppsByUrl.reduce((result: UnifiedApplication[], app: any): UnifiedApplication[] => {
      if (!urlClientNames.has(app.name)) {
        urlClientNames.add(app.name);
        result.push({
          enabled: false,
          sso: false,
          uuid: '',
          categories: [],
          type: 'urlClient',
          ...app,
          callbacks: [],
        });
      }

      return result;
    }, []);
  } catch (error) {
    captureMessage('Failed to fetch URL clients in customApplicationHelpers.ts');
    captureException(error);
    throw new Error('Failed to fetch URL clients');
  }
};
